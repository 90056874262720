import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DishLabel } from '@interfaces/dish-label.interface';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { TooltipModule } from 'primeng/tooltip';

const PRIME_NG_MODULES = [TooltipModule];

@Component({
  selector: 'app-dish-labels',
  standalone: true,
  imports: [PRIME_NG_MODULES, SvgIconComponent, TranslateModule],
  templateUrl: './dish-labels.component.html',
  styleUrl: './dish-labels.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishLabelsComponent {
  labels = input<DishLabel[] | undefined>([]);
}
